import React, { Fragment, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import stealth from '@stltio/stealth'

import Nav from './Nav/Nav'
import ScrollTop from './ScrollTop/ScrollTop'
import LoadCollections from './LoadCollections/LoadCollections'
import Payoff from './Payoff/Payoff'
import Contacts from './Contacts/Contacts'
import Footer from './Footer/Footer'

const Layout = () => {
  const location = useLocation()

  useEffect(() => {
    stealth({
      apiKey: '292e82952f75c4c9649a5c4708399d00a2a32d5874aa7a10e65dd679bf26a376'
    })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Fragment>
      <Nav />
      <Outlet />
      <Payoff />
      <Contacts />
      <ScrollTop />
      <Footer />
      <LoadCollections />
    </Fragment>
  )
}

export default Layout
