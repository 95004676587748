import css from './Payoff.module.scss'

const Payoff = () => (
  <div className={css.Payoff}>
    <div className={css.Inside}>
      Purtroppo non esiste l'arte marziale migliore per eccellenza, ma di una
      cosa siamo sicuri: l'ESPERIENZA.
      <br />
      <br />
      La professionalità e la competenza acquisita in tanti anni, fanno della
      DEFENDO, un gruppo di esperti nel settore della difesa personale.
    </div>
  </div>
)

export default Payoff
